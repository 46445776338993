import { makeStyles, Paper, Button } from '@material-ui/core'
import React, { useState, useEffect, useContext } from 'react'
import { deleteWorkshop, getWorkshops } from '../CreationIndex'
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import { AuthenticationContext } from '../../App';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Modal from '@material-ui/core/Modal';
import ShareIcon from '@material-ui/icons/Share';

const useStyles = makeStyles({

    root: {
        minHeight: '800px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: '20px',
        flexDirection: 'column'
    },
    creationContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        minHeight: '800px',
        width: '100%',
        height: '100%',
        border: '2px solid white',
        borderRadius: '10px',
        background: 'rgba(255,255,255,0.7)',
        padding: '10px',
    },
    paper: {
        width: '40vh',
        minHeight: '30vh',
        maxHeight: '70vh',
        margin: '30px',
        overflowWrap: 'wrap',
        padding: '5px',
        '& p': {
            margin: '0px'
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: "relative"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        overflow: 'scroll',
        // margin: '20px',

    },
    modalImage: {
        width: 'auto',
        height: 'auto',
        maxHeight: '100vh',
        '@media (max-width: 600px)': {
            width: '90vw'
        }
    },
    h1: {
        fontSize: '60px',
        color: 'maroon',
        '@media (max-width:450px)': {
            fontSize: '30px'
        }
    },
})

const deleteItem = (itemId, index, tokenValue, images, setImages, setShowDeleteWarning) => {
    deleteWorkshop(itemId, index, tokenValue, images, setImages, setShowDeleteWarning)
}

const closeSnackBar = (snackBarFunction) => {
    snackBarFunction(false)
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function MyWorkshops() {
    const classes = useStyles();
    const [images, setImages] = useState([]);
    const [showLoader, setShowLoader] = useState(true)
    const authContext = useContext(AuthenticationContext);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false)

    const [openLargeImage, setOpenLargeImage] = useState(-1);

    useEffect(() => {
        // getImages(setImages)
        getWorkshops(setImages, setShowLoader)
    }, [])

    return (
        <div className={classes.root}>
            <h1 className={classes.h1}>Workshops</h1>

            {
                showLoader == true ?
                    <LinearProgress />
                    :

                    <div className={classes.creationContainer}>
                        {
                            images.map((photo, index) => {
                                return (<Paper className={classes.paper} elevation="3">
                                    {authContext.tokenValue &&
                                        <IconButton color="primary" aria-label="upload picture" component="span" style={{ position: "absolute", transitionDuration: "0.25s", top: '0', right: '0' }} onClick={() => { deleteItem(photo.imageId, index, authContext.tokenValue, images, setImages, setShowDeleteWarning) }}>
                                            <Delete />
                                        </IconButton>
                                    }
                                    <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                        <div style={{ height: 'auto', width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            <img src={photo.image} alt="Mandala" width="100%" height="auto" onClick={() => setOpenLargeImage(index)} />
                                        </div>
                                    </div>
                                    <div style={{ justifyContent: 'flex-end', width: '100%' }}>
                                        <div>
                                            <p>{photo.description}</p>
                                            <div>
                                                <span>Start Date: {new Date(photo.startDate).getFullYear()}</span>
                                            </div>
                                            <div style={{ justifyContent: 'space-around', flex: 1 }}>
                                                <IconButton>
                                                    <ShareIcon />
                                                </IconButton>
                                                <Button variant="contained" color="primary">
                                                    Register
                                            </Button>
                                            </div>
                                        </div>
                                    </div>

                                </Paper>)
                            })
                        }
                    </div>

            }
            <Modal
                className={classes.modal}
                open={openLargeImage == -1 ? false : true}
                onClose={() => setOpenLargeImage(-1)}
                onBackdropClick={() => setOpenLargeImage(-1)}
            >
                <WorkShopImage image={openLargeImage != -1 && images[openLargeImage].image} />
            </Modal>
            <Snackbar open={showDeleteWarning} autoHideDuration={6000} onClose={() => { closeSnackBar(setShowDeleteWarning) }}>
                <Alert onClose={() => { closeSnackBar(setShowDeleteWarning) }} severity="warning">
                    Item has been deleted
                </Alert>
            </Snackbar>
        </div>
    )
}


const WorkShopImage = (props) => {

    const classes = useStyles();

    console.log(props.image)

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img className={classes.modalImage} src={props.image} />
        </div>
    );
}

export default MyWorkshops
