import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import BookIcon from '@material-ui/icons/Book';
import CreateIcon from '@material-ui/icons/Create';
import StorefrontIcon from '@material-ui/icons/Storefront';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Publish from '@material-ui/icons/Publish';
// import PhotoIcon from '@material-ui/icons/Photo';
import Fab from '@material-ui/core/Fab';
import DehazeIcon from '@material-ui/icons/Dehaze';
import Photo from '../media/Logo.png'
import { Link } from 'react-router-dom';
import { AuthenticationContext } from '../App';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert'

const useStyles = makeStyles({
    list: {
        width: "250px",
        color: 'white'
    },
    fullList: {
        width: 'auto',
    },
    image: {
        width: "200px",
        paddingBottom: "1rem",
    },
    fab: {
        margin: 0,
        top: 20,
        '@media (max-width:600px)': {
            top: 7,
        },
        right: 'auto',
        bottom: 'auto',
        left: 10,
        position: 'absolute',
        background: 'rgba(212,30,30,0)',
        boxShadow: '0 0 0 0 #ccc'

    },
    paper: {
        background: 'rgba(12,34,38,1)'
    },
    paperTextColor: {
        color: 'white'
    },
    IconColor: {
        color: 'white',
    }
});

const doLogout = (authContext, setShowSnackBar) => {
    authContext.setTokenValue(null)
    localStorage.removeItem('authTokenValue')
    setShowSnackBar(true)
}

const handleClose = (closeFunction) => {
    closeFunction(false)
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function TemporaryDrawer() {
    const classes = useStyles();
    const [openDrawer, setState] = React.useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const authContext = useContext(AuthenticationContext);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };


    const list = (anchor) => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Divider />
            <List>
                <ListItem>
                    <img src={Photo} alt="Hehehe" className={classes.image} />
                </ListItem>

                <Divider className={classes.IconColor} />

                <ListItem button component={Link} to={"/"}>
                    <ListItemIcon><HomeIcon className={classes.IconColor} /></ListItemIcon>
                    <ListItemText primary="Home" className={classes.paperTextColor} />
                </ListItem>

                <ListItem button component={Link} to={"/mycreations"}>
                    <ListItemIcon><CreateIcon className={classes.IconColor} /></ListItemIcon>
                    <ListItemText primary="My Creations" className={classes.paperTextColor} />
                </ListItem>


               


                <ListItem button component={Link} to={"/workshop"}>
                    <ListItemIcon><StorefrontIcon className={classes.IconColor} /></ListItemIcon>
                    <ListItemText primary="Workshops" className={classes.paperTextColor} />
                </ListItem>

                {
                    authContext.tokenValue &&

                    <ListItem button component={Link} to={"/upload"}>
                        <ListItemIcon><Publish className={classes.IconColor} /></ListItemIcon>
                        <ListItemText primary="Upload" className={classes.paperTextColor} />
                    </ListItem>
                }

                <ListItem button component={Link} to={"/blogsandpoems"}>
                    <ListItemIcon><BookIcon className={classes.IconColor} /></ListItemIcon>
                    <ListItemText primary="Poems Corner" className={classes.paperTextColor} />
                </ListItem>


                {
                    authContext.tokenValue == null ?
                        <ListItem button component={Link} to={"/login"}>
                            <ListItemIcon><AccountCircle className={classes.IconColor} /></ListItemIcon>
                            <ListItemText primary="Login" className={classes.paperTextColor} />
                        </ListItem>

                        :

                        <ListItem button onClick={() => { doLogout(authContext, setShowSnackBar) }}>
                            <ListItemIcon><ExitToApp className={classes.IconColor} /></ListItemIcon>
                            <ListItemText primary="Logout" className={classes.paperTextColor} />
                        </ListItem>
                }
            </List>
        </div>
    );

    return (
        <div>

            <Snackbar open={showSnackBar} autoHideDuration={2000} onClose={() => { handleClose(setShowSnackBar) }}>
                <Alert onClose={() => { handleClose(setShowSnackBar) }} severity="info">
                    Logged out succesfully
                </Alert>
            </Snackbar>
            <React.Fragment key='left'>
                <Fab aria-label="add" className={classes.fab} onClick={toggleDrawer('left', true)}>
                    <DehazeIcon />
                </Fab>
                <Drawer classes={{ paper: classes.paper }} anchor='left' open={openDrawer} onClose={toggleDrawer('left', false)}>
                    {list('left')}
                </Drawer>
            </React.Fragment>
        </div>
    );
}