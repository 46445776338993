import React from 'react'
import { makeStyles, Typography } from '@material-ui/core';
import TestImage from '../media/Picture2.jpeg'


const useStyles = makeStyles(() => ({
    ContactUsContainer: {
        display: 'flex',
        // justifyContent: 'center',
        background: 'blue',
        height: '700px',
        marginTop: '20px',
        marginLeft: '5%',
        marginRight: '5%',
        padding: '1%',
        borderRadius: '2%'
    },
    TextConent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: '7%',
        paddingBottom: '10%',
        background: 'yellow',
        width: '50%',
        textAlign: 'left',
    },
    ImageContent: {
        display: 'flex',
        overflowWrap: 'normal',
        background: 'purple',
        width: '50%',
        position: 'relative',
        ' & img': {
            display: 'block',
            width: '100%'
        },
        '&:after': {
            content: '""',
            display: 'block',
            width: '100.5%',
            height: '100.5%',
            background: 'radial-gradient(circle at center, rgba(255,255,255,0) 0%,blue 65%,blue 1%)',
            position: 'absolute',
            top: '0',
            left: '0',
            marginTop: '-0.1%',
            marginLeft: '-0.1%'
        },

    },
    FloatingDiv: {
        height: '600px',
        width: '1000px',
        background: 'grey',
        position: 'absolute',
        zIndex: '1',
        float: 'left',
        top: '600px',
        left: '450px',
    },
    DummyDiv: {
        height: '500px'
    }
}))

function ContactUs() {
    const classes = useStyles();
    return (
        <>
            <div className={classes.ContactUsContainer}>
                <div className={classes.TextConent}>
                    <Typography variant="h2">
                        Contact Us
                    </Typography>
                    <Typography variant="h4">
                        On any of our social media platform
                    </Typography>
                </div>
                <div className={classes.ImageContent}>
                    <img src={TestImage} alt="Background" />
                </div>
            </div>
            <div className={classes.DummyDiv}>
            </div>
            <div className={classes.FloatingDiv}>

            </div>
        </>
    )
}

export default ContactUs
