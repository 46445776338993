import axios from 'axios';

const BASE_URL = "https://asia-south1-sreevatsa-website.cloudfunctions.net/app"
// const BASE_URL = "http://localhost:5000/sreevatsa-website/asia-south1/app";

export const getImages = async (imageSetFunction) => {
    fetch(BASE_URL + '/imagecount').then((data) => {
        let actualImageCount = data.json();
        actualImageCount.then((imageCount) => {
            console.log(imageCount)
            let images = [];
            for (let imageIndex = 0; imageIndex <= imageCount.image_count; imageIndex++) {
                fetch(BASE_URL + '/creations/' + imageIndex).then((creationData) => {
                    let actualCreationData = creationData.json();
                    actualCreationData.then((myCreation) => {
                        let actualImage = {
                            image: myCreation.image,
                            description: myCreation.description
                        }
                        images.push(actualImage);
                        console.log(myCreation.description)
                        imageSetFunction([...images])
                    })
                })
            }
        })
    })
}

export const getCreations = async (imageSetFunction, setShowLoader, id, setOpenLargeImage, setComments) => {
    fetch(BASE_URL + '/creations').then((data) => {
        let actualCreations = data.json();
        let finalImages = [];
        setShowLoader(false)
        actualCreations.then((creations) => {
            console.log(creations)
            for (let image in creations.images) {
                if (image == "placeholder") {
                    continue;
                }
                console.log(creations.images[image], "Single image")

                let actualImage = {
                    image: creations.images[image].url,
                    description: creations.images[image].description,
                    imageId: image,
                    upload_date: creations.images[image].upload_date,
                    nComments: creations.images[image].number_of_comments,
                    nLikes: creations.images[image].number_of_likes,
                    isLiked: creations.images[image].isLiked
                }
                finalImages.push(actualImage);
                finalImages.sort((item1, item2) => {
                    let date1 = new Date(item1.upload_date)
                    let date2 = new Date(item2.upload_date)

                    return date1.getTime() > date2.getTime() ? -1 : 1
                })
                imageSetFunction([...finalImages])
            }
            if (id != null) {
                let imageIndex = finalImages.findIndex(e => e.imageId == id)
                setOpenLargeImage(imageIndex);
                getComments(id, setComments);
     
             }
        })
    })
}

export const getWorkshops = async (imageSetFunction, setShowLoader) => {
    fetch(BASE_URL + '/workshops').then((data) => {
        let actualCreations = data.json();
        let finalImages = [];
        setShowLoader(false)
        actualCreations.then((creations) => {
            console.log(creations)
            for (let image in creations.images) {
                if (image == "placeholder") {
                    continue;
                }
                console.log(creations.images[image], "Single image")

                let actualImage = {
                    image: creations.images[image].url,
                    description: creations.images[image].description,
                    imageId: image,
                    startDate: creations.images[image].start_date,
                }
                finalImages.push(actualImage);
                imageSetFunction([...finalImages])
            }
        })
    })
}

export const getPoems = async (imageSetFunction, setShowLoader) => {
    fetch(BASE_URL + '/poems').then((data) => {
        let actualCreations = data.json();
        let finalImages = [];
        setShowLoader(false)
        actualCreations.then((creations) => {
            console.log(creations)
            for (let image in creations.images) {
                if (image == "placeholder") {
                    continue;
                }
                console.log(creations.images[image], "Single image")

                let actualImage = {
                    image: creations.images[image].url,
                    description: creations.images[image].description,
                    imageId: image
                }
                finalImages.push(actualImage);
                imageSetFunction([...finalImages])
            }
        })
    })
}

export const loginFunction = async (setTokenValue, userName, password, successSnackBar, failureSnackBar, redirector, setSpinner) => {
    fetch(BASE_URL + '/login', {
        method: 'POST', // or 'PUT',
        body: JSON.stringify({ "username": userName, "password": password })
    }
    ).then((data) => {
        setSpinner(false)
        console.log(data)
        data.json().then((loginResponse) => {
            console.log(loginResponse)
            if (loginResponse.token != null) {
                setTokenValue(loginResponse.token)
                localStorage.setItem('authTokenValue', loginResponse.token)
                successSnackBar({
                    status: true,
                    message: loginResponse.message
                })

                redirector.push('/')
            }

            else {
                failureSnackBar({
                    status: true,
                    message: loginResponse.message
                })
            }
        })
    })
}

export const uploadImageToFireBase = (image, setSpinner, successSnackBar, failureSnackBar, setPreviewImage) => {
    setSpinner(true)
    fetch(BASE_URL + '/upload', {
        method: 'POST',
        body: image
    }).then((data) => {
        console.log("Intermediate data")
        data.json().then((actualResponse) => {

            console.log(actualResponse, "Actual response")
            setSpinner(false)

            if (actualResponse.success == true) {
                successSnackBar({
                    status: true,
                    message: actualResponse.message
                })
            }

            else {
                failureSnackBar({
                    status: true,
                    message: actualResponse.message
                })
            }

            setPreviewImage(null)
        })
    })
}

export const deleteCreation = (imageId, localImageIndex, tokenValue, images, setImages, setShowDeleteWarning) => {

    fetch(BASE_URL + '/deletecreation?image_id=' + imageId + "&token=" + tokenValue)
        .then((data) => {
            data.json().then((actualResponse) => {

                if (actualResponse.success == true) {
                    console.log(actualResponse)
                    setShowDeleteWarning(true)
                    images.splice(localImageIndex, 1);
                    setImages([...images])
                }
            })
        })
}

export const deleteWorkshop = (imageId, localImageIndex, tokenValue, images, setImages, setShowDeleteWarning) => {

    fetch(BASE_URL + '/deleteworkshop?image_id=' + imageId + "&token=" + tokenValue)
        .then((data) => {
            data.json().then((actualResponse) => {

                if (actualResponse.success == true) {
                    console.log(actualResponse)
                    setShowDeleteWarning(true)
                    images.splice(localImageIndex, 1);
                    setImages([...images])
                }
            })
        })
}

export const deletePoem = (imageId, localImageIndex, tokenValue, images, setImages, setShowDeleteWarning) => {

    fetch(BASE_URL + '/deletepoem?image_id=' + imageId + "&token=" + tokenValue)
        .then((data) => {
            data.json().then((actualResponse) => {

                if (actualResponse.success == true) {
                    console.log(actualResponse)
                    setShowDeleteWarning(true)
                    images.splice(localImageIndex, 1);
                    setImages([...images])
                }
            })
        })
}

export const postComment = (imageId, displayName, comment, comments, setComments, setSpinner, images, setImages, index) => {
    setSpinner(true)
    fetch(BASE_URL + '/comment', {
        method: 'POST', // or 'PUT',
        body: JSON.stringify({ "image_id": imageId, "display_name": displayName, "comment": comment })
    }).then((response) => response.json().then((actualResponse) => {
        console.log(actualResponse)
        let newComments = [...comments]
        let newImages = [...images]
        let newCommentObj = {
            "commentId": actualResponse.comment.commentId,
            "comment": actualResponse.comment.message,
            "displayName": actualResponse.comment.displayName,
            "postedOn": actualResponse.comment.postedOn,
        }
        newImages[index].nComments += 1;
        console.log("New Images: ", newImages[index])
        setImages([...newImages])
        newComments.unshift(newCommentObj)
        setComments([...newComments])
        setSpinner(false)
    }))
}

export const getComments = (imageId, setComments) => {
    fetch(BASE_URL + '/comment?image_id=' + imageId)
        .then(data => data.json().then((actualResponse) => {
            console.log(actualResponse)
            let finalComments = [];
            for (let comment in actualResponse.comments) {
                let commentObject = {
                    "commentId": comment,
                    "comment": actualResponse.comments[comment].message,
                    "displayName": actualResponse.comments[comment].displayName,
                    "postedOn": actualResponse.comments[comment].postedOn,
                }

                finalComments.push(commentObject)
            }
            console.log("Final comments ", finalComments)
            finalComments.sort((comment1, comment2) => {
                let date1 = new Date(comment1.postedOn);
                let date2 = new Date(comment2.postedOn)

                return date1 > date2 ? -1 : 1;
            })
            setComments([...finalComments])
        }))
}

export const deleteComment = (imageId, commentId, comments, setComments, setSpinner, images, setImages, index) => {
    setSpinner(true)
    fetch(BASE_URL + '/deletecomment?image_id=' + imageId + "&comment_id=" + commentId)
        .then(data => data.json().then((actualResponse) => {
            console.log(actualResponse)
            let newComments = [...comments]
            comments.forEach((element, index) => {
                if (element.commentId == commentId) {
                    newComments.splice(index, 1);
                    return;
                }
            })

            let newImages = [...images]
            newImages[index].nComments -= 1;
            console.log("New Images: ", newImages[index])
            setImages([...newImages])
            setComments([...newComments])
            setSpinner(false)
        }))
}

export const likeImage = (imageId, images, setImages, index) => {
    fetch(BASE_URL + '/like', {
        method: 'POST', // or 'PUT',
        body: JSON.stringify({ "image_id": imageId}),
    }).then(data => data.json().then((actualResponse) => {
        let newImages = [...images]
        if (images[index].isLiked == true) {
            newImages[index].isLiked = false;
            newImages[index].nLikes -= 1;
        }

        else {
            newImages[index].isLiked = true;
            newImages[index].nLikes += 1;
        }

        setImages(newImages)
    }))
}