import React from 'react'
import Container from '@material-ui/core/Container';
import { Box, createMuiTheme, CssBaseline, ThemeProvider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DCCAshwoff2 from '../fonts/DCC-Ash.woff2';
import KopanyicaStrasse from '../fonts/KopanyicaStrasse.woff2';
import { yellow } from '@material-ui/core/colors';
import Background from '../media/BannerBackground.png'

const dccash = {
    fontFamily: 'DCCAsh',
    fontStyle: 'cursive',
    fontDisplay: 'swap',
    fontWeight: '400',
    src: `
    url(${DCCAshwoff2}) format('woff2')
    `
}

const kopany = {
    fontFamily: 'Kopany',
    fontStyle: 'cursive',
    fontDisplay: 'swap',
    fontWeight: '800',
    src: `
    url(${KopanyicaStrasse}) format('woff2')
    `
}

const theme = createMuiTheme({
    typography: {
        fontFamily: ['Kopany'].join(','),
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [kopany],
            },
        },
    },
    palette: {
        primary: yellow
    }
});

const useStyles = makeStyles((theme) => ({
    BannerStyles: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px',
        backgroundColor: 'rgba(240,53,53,1)',
        backgroundImage: `url(${Background})`,
        '@media (max-width:450px)': {
            height: '6.8rem'
        },
        '@media (max-width:940px) and (min-width: 450px)': {
            height: '9rem'
        }
    },
    BannerTitle: {
        height: "80%",
        fontSize: "10rem",
        fontFamily: 'Kopany',
        padding: '0rem',
        marginBottom: "5rem",
        '@media (max-width:450px)': {
            fontSize: '1.7rem',
            height: 'auto',
            marginBottom: '0rem !important',
        },
        '@media (max-width:940px) and (min-width: 450px)': {
            fontSize: '3.6rem',
            height: 'auto',
            marginBottom: '0rem !important',
        },
    },
    Box: {
        fontSize: '7rem',
        background: 'rgba(240,53,53,0.3)',
        borderRadius: '50px',
        '@media (max-width:450px)': {
            background: 'rgba(240,53,53,0.12)',
            letterSpacing: '14px',
            fontSize: '2.2rem'
        },
        '@media (max-width:940px) and (min-width: 450px)': {
            background: 'rgba(240,53,53,0.12)',
            letterSpacing: '17px',
            fontSize: '4.8rem'
        }
    },
    Box2: {
        fontSize: '3rem',
        background: 'rgba(240,53,53,0.3)',
        borderRadius: '50px',
        '@media (max-width:450px)': {
            background: 'rgba(240,53,53,0.12)',
            letterSpacing: '14px',
            fontSize: '1rem'
        },
        '@media (max-width:940px) and (min-width: 450px)': {
            background: 'rgba(240,53,53,0.12)',
            letterSpacing: '17px',
            fontSize: '2rem'
        }
    }
}));

function Banner() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container className={classes.BannerStyles} maxWidth={false} disableGutters="true">
                    <Typography className={classes.BannerTitle} color="primary">
                        <Box className={classes.Box2} letterSpacing={20}>
                            STUDIO OF
                    </Box>
                        <Box className={classes.Box} letterSpacing={20}>
                            EXPRESSIONS
                    </Box>
                    </Typography>
                </Container>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default Banner
