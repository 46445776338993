import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { AuthenticationContext } from '../../App';
import { uploadImageToFireBase } from '../CreationIndex';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles({

    root: {
        minHeight: '800px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: '20px',
        flexDirection: 'column',
        padding: "0px!important"
    }
})

const uploadImage = (image, category, description, formUrl, startDate, tokenValue, setSpinner, successSnackBar, failureSnackBar, setPreviewImage) => {
    console.log(image)
    console.log(category)
    const data = new FormData();
    data.append('image', image);
    data.append('category', category)
    data.append('description', description)
    data.append('authorization', tokenValue)

    if (category == 1) {
        data.append('formUrl', formUrl)
        data.append('startDate', startDate.toString())
    }

    uploadImageToFireBase(data, setSpinner, successSnackBar, failureSnackBar, setPreviewImage);
}

const previewImage = (data, imageSetFunction, imagePreviewSet) => {
    imageSetFunction(data.target.files[0])
    imagePreviewSet(URL.createObjectURL(data.target.files[0]))
}

const handleClose = (closeFunction) => {
    closeFunction({
        status: false,
        message: ""
    })
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Upload() {

    const classes = useStyles();
    const [image, setImage] = useState();
    const [category, setCategory] = useState(2);
    const [preImage, setPreviewImage] = useState();
    const [description, setDescription] = useState();
    const [spinner, setSpinner] = useState(false);
    const [formUrl, setFormUrl] = useState()
    const [startDate, setStartDate] = useState(new Date())
    const authContext = useContext(AuthenticationContext);

    const [successSnackBar, setSuccessSnackBar] = useState({
        status: false,
        message: ""
    })

    const [failureSnackBar, setFailureSnackBar] = useState({
        status: false,
        message: ""
    })

    return (
        <div className={classes.root}>

            <Snackbar open={successSnackBar.status} autoHideDuration={2000} onClose={() => { handleClose(setSuccessSnackBar) }}>
                <Alert onClose={() => { handleClose(setSuccessSnackBar) }} severity="success">
                    {successSnackBar.message}
                </Alert>
            </Snackbar>

            <Snackbar open={failureSnackBar.status} autoHideDuration={2000} onClose={() => { handleClose(setFailureSnackBar) }}>
                <Alert onClose={() => { handleClose(setFailureSnackBar) }} severity="error">
                    {failureSnackBar.message}
                </Alert>
            </Snackbar>
            <form noValidate>

                {
                    preImage == null ?

                        <Container style={{ backgroundColor: '#cfe8fc', height: '50vh', borderRadius: "10px", paddingRight: "0px", paddingLeft: "0px", border: "dashed" }} >
                            <Button style={{ height: "100%", width: "100%" }} component="label">
                                Upload File
                                <input type="file" accept="image/*" hidden onChange={(data) => { previewImage(data, setImage, setPreviewImage) }} />
                            </Button>
                        </Container>

                        :

                        <Container style={{ backgroundColor: '#cfe8fc', height: '50vh', borderRadius: "10px", paddingRight: "0px", paddingLeft: "0px", display: "flex", alignItems: "center", justifyContent: "center" }} >
                            <img src={preImage} style={{ height: "auto", width: "auto", maxHeight: "100%", maxWidth: "100%" }} />
                        </Container>
                }

                <Container style={{ marginTop: "2vh" }}>

                    <Grid container spacing={3}>

                        <Grid item xs={6}>

                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-controlled-open-select-label">Category</InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    onChange={(data) => { setCategory(data.target.value) }}
                                    value={category}
                                >
                                    <MenuItem value={1}>Workshop</MenuItem>
                                    <MenuItem value={2}>My Creation</MenuItem>
                                    <MenuItem value={3}>Poems</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>

                        <Grid item xs={6}>

                            <TextField id="standard-basic" label="Description" onChange={(data) => setDescription(data.target.value)} />

                        </Grid>

                        {
                            category == 1 &&

                            <Grid item xs={5}>

                                <TextField id="standard-basic" label="Form URL" onChange={(data) => setFormUrl(data.target.value)} />

                            </Grid>
                        }

                        {
                            category == 1 &&

                            <Grid item xs={7}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Start Date"
                                        format="dd/MM/yy"
                                        value={startDate}
                                        onChange={(date) => { setStartDate(date) }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>

                            </Grid>
                        }

                        <Grid item xs={6}>

                            <Button variant="contained" color="primary" disabled={spinner} onClick={() => {
                                setPreviewImage(null)
                            }}>
                                Clear
                            </Button>

                        </Grid>

                        <Grid item xs={6}>

                            <Button variant="contained" color="primary" disabled={spinner} onClick={() => {
                                uploadImage(image, category, description, formUrl, startDate ,authContext.tokenValue, setSpinner, setSuccessSnackBar, setFailureSnackBar, setPreviewImage)
                            }}>
                                {
                                    spinner == true ?
                                        <CircularProgress />

                                        :

                                        "Upload"
                                }
                            </Button>

                        </Grid>

                    </Grid>

                </Container>

            </form>
        </div>
    )
}

export default Upload;