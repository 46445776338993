import React from 'react';
import { useState, useCallback } from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, makeStyles } from '@material-ui/core'
import Images from './CarouselIndex'
import Backdrop from '@material-ui/core/Backdrop'
import PosterPhoto from '../media/Poster.jpg'


const useStyles = makeStyles((theme) => ({
    //Actual Carousel
    paperHeight: {
        height: "44%",
        width: "100%",
        boxShadow: '0 0px 0px 0px black',
        background: 'rgba(255,255,255,0)',
        '@media (max-width:600px)': {
            width: "100%",
            height: '350px'
        },
    },
    //Photo
    phoneSizePaper: {
        padding: "20px",
        height: "500px",
        '@media (max-width:600px)': {
            width: "90%",
            height: "auto"
        },
        zIndex: '3'
    },
    //Opened Image in Backdrop
    largeImage: {
        maxWidth: '90%',
        maxHeight: '90%',
        padding: '5px',
        '@media (max-width:600px)': {
            height: 'auto',
            width: '90%'
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    test: {
        flex: '1'
    }
}))



export function Example() {

    const [selectedImage, setSelectedImage] = useState(0)

    const classes = useStyles();
    const [stopScroll, setStopScroll] = useState(true)
    const scrollProp = useCallback(
        (e) => {
            setStopScroll(e)
        },
        [],
    )
    /*
    
    Use this way, if you want to embed any more details to the Carousel item (Like descrition).
    To do that just do:

    {
        img: Img12,
        description: "LOL TROLL",
        title: "Bob the builder"
    }
    
    var items = [
        {
            img: Img12
        },
        {
            img: Img1
        },
        {
            img: Img2
        },
        {
            img: Img3
        },
        {
            img: Img4 
        }
    ]*/

    return (
        <div className={classes.test}>
            <Carousel interval="5000" animation="slide" indicators="true" autoPlay={stopScroll}>
                {
                    Images.map((item, i) => {
                        return (
                            <div style={{position: 'relative', overflow: 'hidden'}}>
                                <div style={{ backgroundImage: `url(${item})`, filter: 'blur(100px)', zIndex: '-1', position: 'absolute', height: '100%', width: '100%' }}></div>
                                <Item key={i} item={item} scrollerControl={scrollProp} setSelectedImage={setSelectedImage} />
                            </div>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}

const Item = (props) => {
    const classes = useStyles();

    props.setSelectedImage(props.key)
    const [isImageVisible, setImageVisibility] = useState(false)
    return (
        <Paper className={classes.paperHeight}>

            <img src={props.item} alt="WorkshopResults" className={classes.phoneSizePaper} onClick={() => {
                setImageVisibility(true)
                props.scrollerControl(false)
            }} />
            {isImageVisible ?
                <Backdrop className={classes.backdrop} open='true' onClick={() => {
                    setImageVisibility(false)
                    props.scrollerControl(true)
                }}>
                    <img src={props.item} alt="WorkshopResults" className={classes.largeImage} />
                </Backdrop>
                :
                null}

        </Paper>
    )
}

export const WorkShopCarousel = () => {

    const [isImageVisible, setImageVisibility] = useState(false)

    const classes = useStyles();
    return (
        <Carousel interval="5000" animation="slide" indicators="true" >

            <div>
                <img src={PosterPhoto} className={classes.largeImage} onClick={() => { setImageVisibility(true) }} />
                {
                    isImageVisible ? <Backdrop className={classes.backdrop} open='true' onClick={() => setImageVisibility(false)}><img src={PosterPhoto} className={classes.largeImage} /></Backdrop>
                        :
                        null
                }
            </div>

        </Carousel>
    );
}