import React from 'react'
import { makeStyles, Typography } from '@material-ui/core';
import InstagramLogo from '../media/instagram_logo.webp'
import FacebookLogo from '../media/facebook_logo.png'
import GmailLogo from '../media/gmail_logo.svg'
import WhatsAppLogo from '../media/WhatsApp_logo.svg.png'

const useStyles = makeStyles({
    FooterContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'green',
        minHeight: '15vh',
        padding: '5px',
        border: '2px solid yellow',
        color: 'white',

    },
    DetailsContainer: {
        flex: '1',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    ReachMeThrough: {
        flex: '1',
        fontSize: '50px'
    },
    Links: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontSize: '20px'
    },
    CopyrightContainer: {
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '30px',
        width: '100%',
        fontFamily: 'Times New Roman',
        '@media (max-width:600px)': {
            fontSize: '18px'
        }
    },
    SocialURL: {
        color: 'white',
        textDecoration: 'none',
        display: 'inline',
        fontFamily: 'Times New Roman',
        '@media (max-width: 600px)': {
            display: 'none'
        }
    },
    SocialType: {
        color: 'white',
        textDecoration: 'none',
        display: 'none',
        '@media (max-width: 600px)': {
            display: 'inline'
        }
    }
})

function Footer() {
    const classes = useStyles();
    return (
        <div className={classes.FooterContainer}>
            <div className={classes.DetailsContainer}>
                <div className={classes.ReachMeThrough}>
                    Reach Me Through
                </div>
                <div className={classes.Links}>
                    <div style={{ flex: 1, alignItems: 'center', display: 'flex' }}>
                        <img className={classes.img} src={FacebookLogo} alt="Instagram" height="20px" width="30px" /><a className={classes.SocialType} href="https://www.facebook.com/JayasudhArun" target="_blank">Facebook</a><a href="https://www.facebook.com/JayasudhArun" target="_blank" className={classes.SocialURL}>www.facebook.com/JayasudhArun</a>
                    </div>
                    <div style={{ flex: 1, alignItems: 'center', display: 'flex' }}>
                        <img className={classes.img} src={InstagramLogo} alt="Instagram" height="20px" width="20px" /><a className={classes.SocialType} href="https://www.instagram.com/_.express.ions._" target="_blank">Instagram</a><a href="https://www.instagram.com/_.express.ions._" target="_blank" className={classes.SocialURL}>www.instagram.com/_.express.ions._</a>
                    </div>
                    <div style={{ flex: 1, alignItems: 'center', display: 'flex' }}>
                        <img className={classes.img} src={GmailLogo} alt="Instagram" height="20px" width="20px" /><a className={classes.SocialType}>Gmail</a><a className={classes.SocialURL}>expressionsbyjayasudha@gmail.com</a>
                    </div>
                    <div style={{ flex: 1, alignItems: 'center', display: 'flex' }}>
                        <img className={classes.img} src={WhatsAppLogo} alt="WhatsApp" height="20px" width="20px" /><a className={classes.SocialType}>+91-9886160674</a><a className={classes.SocialURL}>+91-9886160674</a>
                    </div>
                </div>
            </div>
            <div className={classes.CopyrightContainer}>
                <div style={{ flex: 1 }}>
                    © 2021 Copyrights, All Rights Reserved | Design by Expressions
                </div>
            </div>
        </div>
    )
}

export default Footer
