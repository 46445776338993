import { Button, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom';

const usestyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(240,53,53,1)',
        width: 'auto',
        height: 'auto',
        margin: '0px',
        padding: '0px',
        flex: '1'
        
    },
    button: {
        flex: '1',
        borderRadius: '1px',
        textTransform: 'none',
        border: '1px solid yellow',
        borderRadius: '5px',
        '@media (max-width:600px)': {
            fontSize: '11px',
            height: '40px'
        },
        '&:hover' : {
            color: 'white'
        }
    }
})


function NavBar() {

    const classes = usestyles();

    return (
            <div className={classes.root}>
                <Button className={classes.button} color="primary" component={Link} to={"/"}>Home</Button>
                <Button className={classes.button} color="primary" component={Link} to={"/mycreations"}>My Creations</Button>
                <Button className={classes.button} color="primary" component={Link} to={"/workshop"}>Workshops</Button>
                <Button className={classes.button} color="primary" component={Link} to={"/blogsandpoems"}>Poems Corner</Button>
            </div>
    );
}

export default NavBar;