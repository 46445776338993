const reviews = [
    {
        'Rev': 'Delightful and creative patterns capturing the inner essence of the art.',
        'Author': 'Preetham'
    },
    {
        'Rev': 'Absolutely amazing art work! Great arts in different way.',
        'Author': 'Neethu'
    },
    {
        'Rev': 'Be it classical, traditional or folk art, you\'ve always nailed it! Super inspiring!',
        'Author': 'Tanuja'
    },
    {
        'Rev': 'What a wonderful creativity - indeed a joy to watch. A royal treat to eyes.',
        'Author': 'Nandini'
    },
    {
        'Rev': 'Nostalgic Feeling! Looking forward to more nostalgic creations from you!',
        'Author': 'Chaitra'
    }
];

export default reviews;