import { Button, Container, makeStyles } from '@material-ui/core';
import { useState, React } from 'react'
import { Example as CreationCarousel } from '../Carousel';
import Carousel from 'react-material-ui-carousel';
import AboutMePhoto from '../../media/AboutMePhoto.jpg'
import { WorkShopCarousel } from '../Carousel';
import ReviewIndex from '../ReviewIndex';

const useStyles = makeStyles({
    root: {
        minHeight: '800px'
    },
    CarouselContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    aboutMeContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    aboutMeContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
        padding: '10px',
        backgroundColor: 'rgba(138,148,44,0.3)',
        '@media (max-width: 600px)': {
            flexDirection: 'column'
        }
    },
    aboutMePhoto: {
        width: '450px',
        height: '600px',
        '@media (max-width: 600px)': {
            width: '100%',
            height: 'auto'
        }
    },
    aboutMeDetail: {
        flex: 1
    },
    aboutMePara: {
        fontSize: '25px',
        padding: '25px',
        fontFamily: 'Times New Roman',
        fontWeight: 'bolder',
        textAlign: 'left',
        '@media (max-width: 600px)': {
            fontSize: '18px'
        }
    },
    ReviewContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        textAlign: 'center',
        marginTop: '20px',
        marginBottom: '20px',
        minHeight: '350px',
        width: '100%',
        background: 'white',
        borderRadius: '20px',
        border: '1px solid black'
    },
    ReviewText: {
        padding: '10px',
        fontFamily: 'Freestyle Script',
        fontSize: '60px',
        height: '130px',
        '@media (max-width: 600px)': {
            fontSize: '25px'
        }
    },
    h1: {
        fontSize: '60px',
        color: 'maroon',
        '@media (max-width:450px)': {
            fontSize: '30px'
        }
    },
    h2: {
        fontSize: '40px',
        '@media (max-width:450px)': {
            fontSize: '25px'
        }
    },
    MoreReadMoreContent: {
        transitionDuration: '1s'
    },
    headingH1: {
        fontSize: '60px',
        color: 'maroon',
        '@media (max-width:450px)': {
            fontSize: '20px'
        }
    }
})

function Home() {

    const [readMore, setReadMore] = useState(true)

    const classes = useStyles();
    var Reviews = ReviewIndex;
    console.log(Reviews);
    return (
        <div className={classes.root}>
            <h1 className={classes.headingH1}>Welcome to Studio Of Expressions</h1>

            <div className={classes.CarouselContainer}>
                <CreationCarousel />
            </div>

            <h1 className={classes.h1}>Upcoming Workshops</h1>

            <div className={classes.CarouselContainer}>
                <WorkShopCarousel />
            </div>

            <div className={classes.aboutMeContainer}>
                <div className={classes.aboutMeHeader}>
                    <h1 className={classes.h1}>About Me</h1>
                </div>
                <div className={classes.aboutMeContent}>
                    <div>
                        <img className={classes.aboutMePhoto} src={AboutMePhoto}></img>
                    </div>
                    <div className={classes.aboutMeDetail}>
                    <p className={classes.aboutMePara}>
                            My name is Jayasudha. I am a commerce post graduate, having worked in financial operations for 8+ years. The connection between the skills, education and passion drew me closer to express myself through art<a>...</a><a className={classes.MoreReadMoreContent} hidden={readMore}>The artsy skill which was there in me from ages gave way to create my website Studio of Expressions. I believe that being an artist and hobbyist is not a passion but a way of life. I see art in every aspect of life and expressions of art from the heart.<br /><br />
                                I live in the garden city of Bangalore. Today, I am a full time mom of 2 young boys who are up for creative tasks at any given point of time. With them exploring new horizons of my interest paved way for Studio of Expressions. Its a baby step into the e-world of my creations at one place to showcase and to learn from like minded people.<br /><br />
                                Thank you for visiting my website, I am a self trained artist, it definitely inspires me to express and create more… Here you can find my posts of all interests. Collection of Paintings, Mandalas, Madhubani art, DIYs, crafts, Pencil sketches. My biggest passion being blogs and poems.<br /><br />
                                Please like, comment and share your thoughts. I am just an email away : expressionsbyjayasudha@gmail.com</a>
                            <Button onClick={() => setReadMore(!readMore)}>{readMore ? 'Read More' : 'Hide'}</Button>
                        </p>
                    </div>
                </div>
            </div>

            <div>
                <h1 className={classes.h1}>Reviews</h1>
                <Container className={classes.ReviewContainer}>
                    <Carousel interval="3000" indicators indicatorContainerProps={{ style: { display: 'none' } }}>
                        {
                            Reviews.map((review) => <><h1 className={classes.ReviewText}>{review.Rev}</h1><h2 className={classes.h2}>- {review.Author}</h2></>)
                        }
                    </Carousel>
                </Container>
            </div>
        </div>

    )
}

export default Home;
