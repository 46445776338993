import './App.css';
import { createContext, useState, useEffect } from 'react'
import Banner from './components/Banner';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppDrawer from './components/AppDrawer';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import Footer from './components/Footer';

import ContactUs from './components/ContactUs';


import Home from './components/pages/Home';
import BlogsAndPoems from './components/pages/BlogsAndPoems';
import MyCreations from './components/pages/MyCreations';
import Workshop from './components/pages/Workshop';

import Nopage from './components/pages/Nopage';
import NavBar from './components/Navbar';
import Login from './components/pages/Login';
import Upload from './components/pages/Upload';


export const AuthenticationContext = createContext();

const theme = createMuiTheme({
  palette: {
    primary: yellow
  }
});

function App() {

  const [tokenValue, setTokenValue] = useState(localStorage.getItem('authTokenValue'))

  return (
    <div className="App">
      <div className="Test">
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Router>
            <AuthenticationContext.Provider value={{ setTokenValue: setTokenValue, tokenValue: tokenValue }}>
              <Banner />
              <NavBar />
              <AppDrawer />
              <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/blogsandpoems" component={BlogsAndPoems} />
                <Route path="/mycreations/:id" component={MyCreations} />
                <Route path="/mycreations" component={MyCreations} />
                <Route path="/workshop" component={Workshop} />
                <Route path="/login" component={Login} />
                <Route path="/testingsite" component={ContactUs} />
                <Route path="/upload" component={Upload} />
                <Route component={Nopage} />
              </Switch>
            </AuthenticationContext.Provider>
            <Footer />
          </Router>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default App;
