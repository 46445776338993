import { CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import { deleteComment } from './CreationIndex';
import { useContext, useState } from 'react'
import { AuthenticationContext } from '../App';

const useStyles = makeStyles(() => ({
    CommentBox: {
        backgroundColor: 'aliceblue',
        margin: '3px',
        borderRadius: '8px',
        fontFamily: 'Times New Roman',
        wordWrap: 'break-word'
    }
}))

export default Comment = (props) => {

    const classes = useStyles();
    let universalTime = new Date(props.postedOn).toString();
    let postedOn = new Date(universalTime)
    const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const authContext = useContext(AuthenticationContext);
    const [spinner, setSpinner] = useState(false)

    return (
        <div className={classes.CommentBox}>
            <div style={{ padding: '5px', fontSize: '20px', fontWeight: '800' }}>
                {props.displayName}
            </div>
            <div style={{ padding: '5px', fontSize:'20px' }}>
                <div>
                    {props.comment}
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {postedOn.getDate() + " " + shortMonths[postedOn.getMonth()] + " " + postedOn.getFullYear() + " " + ("0" + (postedOn.getHours())).slice(-2) + ":" + ("0" + (postedOn.getMinutes())).slice(-2)}
            </div>
            {
                authContext.tokenValue &&
                <IconButton onClick={() => { deleteComment(props.imageId, props.commentId, props.comments, props.setComments, setSpinner, props.images, props.setImages, props.index) }}>
                { spinner == false ?
                    <Delete />
                    :
                    <CircularProgress color="red" />
                }
                </IconButton>
            }
        </div>
    );
}