import { Button, makeStyles, Paper } from '@material-ui/core'
import React, { useState, useEffect, useContext } from 'react'
import { getCreations, deleteCreation, postComment, getComments, likeImage } from '../CreationIndex'
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import CommentIcon from '@material-ui/icons/Comment';
import Cancel from '@material-ui/icons/Cancel';
import ShareIcon from '@material-ui/icons/Share';
import { AuthenticationContext } from '../../App';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Comment from '../Comment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams, useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({

    root: {
        minHeight: '800px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: '20px',
        flexDirection: 'column'
    },
    creationContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        minHeight: '800px',
        width: '100%',
        height: '100%',
        border: '2px solid white',
        borderRadius: '10px',
        background: 'rgba(255,255,255,0.7)',
        padding: '10px',
    },
    paper: {
        width: '40vh',
        minHeight: '30vh',
        maxHeight: '70vh',
        margin: '30px',
        overflowWrap: 'wrap',
        padding: '5px',
        '& p': {
            margin: '0px'
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: "relative"
    },
    ImageInfoContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'row',
        '@media (max-width: 600px)': {
            flexDirection: 'column'
        }

    },
    Image: {
        maxWidth: '100%',
        maxHeight: '100%'
    },
    Info: {
        flex: '1',
        width: 'auto',

        background: 'white',
        display: 'flex',
        alignSelf: 'stretch',
        '@media (max-width: 600px)': {
            maxWidth: '270px',
            minWidth: '200px',
            height: 'auto',
            minHeight: '100px',
        },
        'media (min-width: 601px)': {
            maxHeight: '90vh',
        }
    },
    ImageContainer: {
        position: 'relative',
        flex: '1',
        width: '650px',
        maxWidth: '1000px',
        height: 'auto',
        background: 'white',
        display: 'flex',
        alignSelf: 'stretch',
        '@media (max-width: 600px)': {
            maxWidth: '270px',
            minWidth: '200px',
            height: 'auto',
            minHeight: '100px',
            margin: '5px 0px 0px 0px !important'
        },
        alignSelf: 'center',
        margin: '50px',
    },
    PictureInfo: {
        padding: '30px',
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        maxHeight: '100%',
        '@media (max-width: 600px)': {
            padding: '10px',
            width: '100%'
        }

    },
    PictureThoughts: {
        padding: '5px',
        '@media (max-width:450px)': {
            padding: '0px',
            margin: '0px !important'
        }
    },
    TextFields: {
        margin: '7px 3px 0px 0px'
    },
    CommentBox: {
        border: '1px solid grey',
        borderRadius: '8px',
        width: '100%',
        height: '400px',
        paddingBottom: '50px',
        overflow: 'scroll'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        overflow: 'scroll',
        // margin: '20px',
        '@media (max-width: 600px)': {
            display: 'block'
        }
    },
    h1: {
        fontSize: '60px',
        color: 'maroon',
        '@media (max-width:450px)': {
            fontSize: '30px'
        }
    },

    Liked: {
        color: "green"
    },

    closeButton: {
        '@media (min-width: 1024px)': {
            display: 'none'
        },
        position: 'absolute',
        top: '0',
        right: '0'
    }
}))

const deleteItem = (itemId, index, tokenValue, images, setImages, setShowDeleteWarning) => {
    deleteCreation(itemId, index, tokenValue, images, setImages, setShowDeleteWarning)
}

const closeSnackBar = (snackBarFunction) => {
    snackBarFunction(false)
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function MyCreations() {
    let { id } = useParams();
    const classes = useStyles();
    const [images, setImages] = useState([]);
    const [showLoader, setShowLoader] = useState(true)
    const [showDeleteWarning, setShowDeleteWarning] = useState(false)
    const [showCopyLink, setShowCopyLink] = useState(false)
    const authContext = useContext(AuthenticationContext);

    const [openLargeImage, setOpenLargeImage] = useState(-1);
    const [comments, setComments] = useState([])

    useEffect(() => {
        // getImages(setImages)
        getCreations(setImages, setShowLoader, id, setOpenLargeImage, setComments)
        console.log("LOLLL", id)
    }, [])

    return (
        <div className={classes.root}>
            <h1 className={classes.h1}>My Creations</h1>

            {
                showLoader == true ?
                    <LinearProgress />
                    :

                    <div className={classes.creationContainer}>
                        {
                            images.map((photo, index) => {
                                return (
                                    <Paper className={classes.paper} elevation={3}>
                                        {authContext.tokenValue &&
                                            <IconButton color="primary" aria-label="upload picture" component="span" style={{ position: "absolute", top: "0", right: "0", transitionDuration: "0.25s" }} onClick={() => { deleteItem(photo.imageId, index, authContext.tokenValue, images, setImages, setShowDeleteWarning) }}>
                                                <Delete />
                                            </IconButton>
                                        }
                                        <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                            <div style={{ height: 'auto', width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                <img src={photo.image} alt="Mandala" width="100%" height="auto" onClick={() => { getComments(photo.imageId, setComments); setOpenLargeImage(index) }} />
                                            </div>
                                        </div>
                                        <div style={{ justifyContent: 'flex-end' }}>
                                            <p>{photo.description}</p>
                                            <IconButton className={photo.isLiked == true ? classes.Liked : classes.Disliked} onClick={() => { likeImage(photo.imageId, images, setImages, index) }}><ThumbUpAltIcon /></IconButton>
                                            <span style={{ fontSize: '16px' }}>{photo.nLikes}</span>
                                            <IconButton onClick={() => { getComments(photo.imageId, setComments); setOpenLargeImage(index) }}><CommentIcon /></IconButton>
                                            <span style={{ fontSize: '16px' }}>{photo.nComments}</span>
                                            <IconButton onClick={() => { navigator.clipboard.writeText(window.location.origin + "/mycreations/" + photo.imageId); setShowCopyLink(true) }}><ShareIcon /></IconButton>
                                        </div>
                                        <Modal
                                            BackdropProps={{
                                                style: {
                                                    opacity: "0.3"
                                                }
                                            }}
                                            className={classes.modal}
                                            open={openLargeImage == -1 ? false : true}
                                            onClose={() => { setOpenLargeImage(-1); setComments([]) }}
                                            onBackdropClick={() => { setOpenLargeImage(-1); setComments([]) }}

                                        >
                                            <Picture image={images} comments={comments} setComments={setComments} index={openLargeImage} setImages={setImages} setOpenLargeImage={setOpenLargeImage} setComments={setComments} />
                                        </Modal>
                                    </Paper>
                                )
                            })
                        }

                    </div>

            }


            <Snackbar open={showDeleteWarning} autoHideDuration={6000} onClose={() => { closeSnackBar(setShowDeleteWarning) }}>
                <Alert onClose={() => { closeSnackBar(setShowDeleteWarning) }} severity="warning">
                    Item has been deleted
                </Alert>
            </Snackbar>
            <Snackbar open={showCopyLink} autoHideDuration={6000} onClose={() => { closeSnackBar(setShowCopyLink) }}>
                <Alert onClose={() => { closeSnackBar(setShowCopyLink) }} severity="success">
                    Link has been copied to your clipboard successfully!
                </Alert>
            </Snackbar>
        </div>
    )
}


const Picture = (props) => {

    const classes = useStyles();
    console.log(props.description)
    const [nameError, setNameError] = useState({
        msg: "",
        error: false
    });
    const [comment, setComment] = useState(null)
    const [displayName, setDisplayName] = useState(null)
    const [spinner, setSpinner] = useState(false)
    const [showCopyLink, setShowCopyLink] = useState(false)

    const commentOnPost = (imageId, displayName, comment, comments, setComments, setSpinner, images, setImages, index) => {
        postComment(imageId, displayName, comment, comments, setComments, setSpinner, images, setImages, index);
    }

    return (
        <div className={classes.ImageInfoContainer}>
            <div className={classes.ImageContainer}>
                <div className={classes.closeButton}> <IconButton color={'secondary'} onClick={() => {props.setOpenLargeImage(-1); props.setComments([])}}><Cancel /> </IconButton></div>
                <img className={classes.Image} src={props.index == -1 ? null : props.image[props.index].image} alt="CreationPhoto" />
            </div>
            <div style={{ display: 'flex', margin: '5px 0px 0px 0px', }}>
                <div style={{ borderRadius: '5px' }} className={classes.Info}>
                    <div className={classes.PictureInfo}>
                        <div>Posted on</div>
                        <div>{props.image.description}</div>
                        <div className={classes.PictureThoughts}>
                            <h2>Share your thoughts</h2>
                            <TextField className={classes.TextFields} id="outlined-basic" label="Name" variant="outlined" fullWidth required error={nameError.error} helperText={nameError.msg} onChange={(text) => { setDisplayName(text.target.value); if (text.target.value.length > 20) { setNameError({ msg: "Name cannot exceed 20 characters", error: true }) } else { setNameError({ msg: "", error: false }) } }} />
                            <TextField className={classes.TextFields} id="outlined-basic" label="Comment" variant="outlined" fullWidth multiline rows="5" rowsMax="8" onChange={(text) => { setComment(text.target.value); }} />
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', flex: '1', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <IconButton className={props.image[props.index].isLiked == true ? classes.Liked : classes.Disliked} onClick={() => { likeImage(props.image[props.index].imageId, props.image, props.setImages, props.index) }}>
                                        <ThumbUpAltIcon />
                                    </IconButton>
                                    <span style={{ fontSize: '16px' }}>{props.image[props.index].nLikes}</span>

                                    <IconButton onClick={() => { navigator.clipboard.writeText(window.location.origin + "/mycreations/" + props.image[props.index].imageId); setShowCopyLink(true) }}>
                                        <ShareIcon />
                                    </IconButton>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Button variant="contained" color="secondary" style={{ margin: '5px' }} onClick={() => commentOnPost(props.image[props.index].imageId, displayName, comment, props.comments, props.setComments, setSpinner, props.image, props.setImages, props.index)}>
                                        {
                                            spinner == true ?

                                                <CircularProgress /> :

                                                "Post"
                                        }
                                    </Button>
                                </div>
                            </div>

                        </div>
                        <Divider variant="middle" style={{ width: '100%' }} />
                        <div style={{ margin: '0px 0px 5px 0px' }}>
                            <h3>Previous Comments</h3>
                            <div className={classes.CommentBox}>
                                {
                                    props.comments.length > 0 ?
                                        props.comments.map((comment) => {
                                            return (
                                                <Comment imageId={props.image[props.index].imageId} commentId={comment.commentId} displayName={comment.displayName} postedOn={comment.postedOn} comment={comment.comment} comments={props.comments} setComments={props.setComments} images={props.image} setImages={props.setImages} index={props.index} />
                                            )
                                        })

                                        :

                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <h3>No Comments to display</h3>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar open={showCopyLink} autoHideDuration={6000} onClose={() => { closeSnackBar(setShowCopyLink) }}>
                <Alert onClose={() => { closeSnackBar(setShowCopyLink) }} severity="success">
                    Link has been copied to your clipboard successfully!
                </Alert>
            </Snackbar>
        </div>
    )

}

export default MyCreations
