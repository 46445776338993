import React from 'react'

function Nopage() {
    return (
        <div>
            ERROR! No such page exists!
        </div>
    )
}

export default Nopage
