import { makeStyles, Paper } from '@material-ui/core'
import React, { useState, useEffect, useContext } from 'react'
import { getImages, getPoems, deletePoem } from '../CreationIndex'
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import { AuthenticationContext } from '../../App';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles({

    root: {
        minHeight: '800px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: '20px',
        flexDirection: 'column'
    },
    creationContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        minHeight: '800px',
        width: '100%',
        height: '100%',
        border: '2px solid white',
        borderRadius: '10px',
        background: 'rgba(255,255,255,0.7)',
        padding: '10px',
    },
    paper: {
        width: '40vh',
        height: '40vh',
        margin: '30px',
        overflowWrap: 'wrap',
        padding: '5px',
        '& p': {
            margin: '0px'
        }
    },
    h1: {
        fontSize: '60px',
        color: 'maroon',
        '@media (max-width:450px)': {
            fontSize: '30px'
        }
    },
})

const deleteItem = (itemId, index, tokenValue, images, setImages, setShowDeleteWarning) => {
    deletePoem(itemId, index, tokenValue, images, setImages, setShowDeleteWarning)
}

const closeSnackBar = (snackBarFunction) => {
    snackBarFunction(false)
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function MyPoems() {
    const classes = useStyles();
    const [images, setImages] = useState([]);
    const [showLoader, setShowLoader] = useState(true)
    const [showDeleteWarning, setShowDeleteWarning] = useState(false)
    const authContext = useContext(AuthenticationContext);

    useEffect(() => {
        // getImages(setImages)
        getPoems(setImages, setShowLoader)
    }, [])

    return (
        <div className={classes.root}>
            <h1 className={classes.h1}>Poems</h1>

            {
                showLoader == true ?
                    <LinearProgress />
                    :

                    <div className={classes.creationContainer}>
                        {
                            images.map((photo, index) => <Paper className={classes.paper} elevation="3">
                                {authContext.tokenValue &&
                                    <IconButton color="primary" aria-label="upload picture" component="span" style={{ position: "absolute", transitionDuration: "0.25s" }} onClick={() => { deleteItem(photo.imageId, index, authContext.tokenValue, images, setImages, setShowDeleteWarning) }}>
                                        <Delete />
                                    </IconButton>
                                }
                                <img src={photo.image} alt="Mandala" width="100%" height="90%" />
                                <p>{photo.description}</p>
                            </Paper>)
                        }
                    </div>

            }

            <Snackbar open={showDeleteWarning} autoHideDuration={6000} onClose={() => { closeSnackBar(setShowDeleteWarning) }}>
                <Alert onClose={() => { closeSnackBar(setShowDeleteWarning) }} severity="warning">
                    Item has been deleted
                </Alert>
            </Snackbar>
        </div>
    )
}

export default MyPoems
