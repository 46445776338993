import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {
    Link as RouteLink
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { AuthenticationContext } from '../../App';
import { loginFunction } from '../CreationIndex';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const appLogin = (authContext, userName, password, successSnackBar, failureSnackBar, redirector, setSpinner) => {
    setSpinner(true)
    loginFunction(authContext.setTokenValue, userName, password, successSnackBar, failureSnackBar, redirector, setSpinner)
}

const handleClose = (closeFunction) => {
    closeFunction({
        status: false,
        message: ""
    })
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function LoginPage() {

    const classes = useStyles();
    const authContext = useContext(AuthenticationContext);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [successSnackBar, setSuccessSnackBar] = useState({
        status: false,
        message: ""
    })

    const [failureSnackBar, setFailureSnackBar] = useState({
        status: false,
        message: ""
    })

    const [spinner, setSpinner] = useState(false)

    const redirector = useHistory();

    return (
        <Container component="main" maxWidth="xs" style={{ minHeight: "700px"}}>
            <CssBaseline />
            <Snackbar open={successSnackBar.status} autoHideDuration={2000} onClose={() => {handleClose(setSuccessSnackBar)}}>
                <Alert onClose={() => {handleClose(setSuccessSnackBar)}} severity="success">
                    {successSnackBar.message}
                </Alert>
            </Snackbar>

            <Snackbar open={failureSnackBar.status} autoHideDuration={2000} onClose={() => {handleClose(setFailureSnackBar)}}>
                <Alert onClose={() => {handleClose(setFailureSnackBar)}} severity="error">
                    {failureSnackBar.message}
                </Alert>
            </Snackbar>
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Log in
        </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        onChange={(data) => { setUserName(data.target.value) }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(data) => { setPassword(data.target.value) }}
                    />
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => { appLogin(authContext, userName, password, setSuccessSnackBar, setFailureSnackBar, redirector, setSpinner) }}
                    >

                        {
                            spinner == false ? 
                                "Sign In"
                            :
                            <CircularProgress />
                        }
          </Button>
                </form>
            </div>
        </Container>
    );
}